import React from "react";
import classNames from "classnames";

function Prev(props) {
  const { currentPage, pagerClass, onPrev } = props;

  const disabled = currentPage <= 1;

  const onPrevClick = (e) => {
    if (disabled) {
      return;
    }
    onPrev(e);
  };

  const pagerPrevClass = classNames(
    pagerClass.default,
    "relative inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-5 text-sm font-medium text-gray-700 hover:bg-gray-50",
    disabled ? pagerClass.disabled : pagerClass.inactive
  );
  return (
    <button onClick={onPrevClick} className={pagerPrevClass}>
      Previous
    </button>
  );
}

export default Prev;
