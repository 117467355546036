import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  photoUrl: "",
  name: "",
  email: "",
  permissions: [],
};

export const userSlice = createSlice({
  name: "auth/profile",
  initialState,
  reducers: {
    setUser: (_, action) => action.payload,
    userLoggedOut: () => initialState,
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
