import React from "react";
import classNames from "classnames";

function Next(props) {
  const { currentPage, pageCount, pagerClass, onNext } = props;

  const disabled = currentPage === pageCount || pageCount === 0;

  const onNextClick = (e) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    onNext(e);
  };

  const pagerNextClass = classNames(
    pagerClass.default,
    "relative inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-5 text-sm font-medium text-gray-700 hover:bg-gray-50",
    disabled ? pagerClass.disabled : pagerClass.inactive
  );

  return (
    <button onClick={onNextClick} className={pagerNextClass}>
      Next
    </button>
  );
}

export default Next;
