import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useConfig } from "../ConfigProvider";
import classNames from "classnames";
import PropTypes from "prop-types";
import Pagination from "../Pagination";
import Next from "./Next";
import Prev from "./Prev";

function PaginationResponsive(props) {
  const { className, currentPage, displayTotal, onChange, pageSize, total } =
    props;

  const [paginationTotal, setPaginationTotal] = useState(total);
  const [internalPageSize, setInternalPageSize] = useState(pageSize);

  const { themeColor, primaryColorLevel } = useConfig();

  const getInternalPageCount = useMemo(() => {
    if (typeof paginationTotal === "number") {
      return Math.ceil(paginationTotal / internalPageSize);
    }
    return null;
  }, [paginationTotal, internalPageSize]);

  const getValidCurrentPage = useCallback(
    (count) => {
      const value = parseInt(count, 10);
      let internalPageCount = getInternalPageCount;
      let resetValue;
      if (!internalPageCount) {
        if (isNaN(value) || value < 1) {
          resetValue = 1;
        }
      } else {
        if (value < 1) {
          resetValue = 1;
        }
        if (value > internalPageCount) {
          resetValue = internalPageCount;
        }
      }

      if ((resetValue === undefined && isNaN(value)) || resetValue === 0) {
        resetValue = 1;
      }

      return resetValue === undefined ? value : resetValue;
    },
    [getInternalPageCount]
  );

  const [internalCurrentPage, setInternalCurrentPage] = useState(
    currentPage ? getValidCurrentPage(currentPage) : 1
  );

  useEffect(() => {
    if (total !== paginationTotal) {
      setPaginationTotal(total);
    }

    if (pageSize !== internalPageSize) {
      setInternalPageSize(pageSize);
    }

    if (currentPage !== internalCurrentPage) {
      setInternalCurrentPage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, pageSize, currentPage]);

  const onPrev = useCallback(() => {
    const newPage = internalCurrentPage - 1;
    setInternalCurrentPage(getValidCurrentPage(newPage));
    onChange?.(getValidCurrentPage(newPage));
  }, [onChange, internalCurrentPage, getValidCurrentPage]);

  const onNext = useCallback(() => {
    const newPage = internalCurrentPage + 1;
    setInternalCurrentPage(getValidCurrentPage(newPage));
    onChange?.(getValidCurrentPage(newPage));
  }, [onChange, internalCurrentPage, getValidCurrentPage]);

  const pagerClass = {
    default: "pagination-pager",
    inactive: "pagination-pager-inactive",
    active: `text-${themeColor}-${primaryColorLevel} bg-${themeColor}-50 hover:bg-${themeColor}-50 dark:bg-${themeColor}-${primaryColorLevel} dark:text-gray-100`,
    disabled: "pagination-pager-disabled",
  };

  const paginationClass = classNames("pagination", className);

  return (
    <div className="flex items-center justify-between rounded-lg border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
      <div className="flex flex-1 justify-between sm:hidden">
        <Prev
          currentPage={internalCurrentPage}
          pagerClass={pagerClass}
          onPrev={onPrev}
        />
        <Next
          currentPage={internalCurrentPage}
          pageCount={getInternalPageCount}
          pagerClass={pagerClass}
          onNext={onNext}
        />
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">1</span> to{" "}
            <span className="font-medium">10</span> of{" "}
            <span className="font-medium">97</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <a
              href="src/components/ui/Pagination#"
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Previous</span>
              <AiOutlineLeft className="h-5 w-5" aria-hidden="true" />
            </a>
            <a
              href="src/components/ui/Pagination#"
              aria-current="page"
              className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
            >
              1
            </a>
            <a
              href="src/components/ui/Pagination#"
              className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              2
            </a>
            <a
              href="src/components/ui/Pagination#"
              className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
            >
              3
            </a>
            <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
              ...
            </span>
            <a
              href="src/components/ui/Pagination#"
              className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
            >
              8
            </a>
            <a
              href="src/components/ui/Pagination#"
              className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              9
            </a>
            <a
              href="src/components/ui/Pagination#"
              className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              10
            </a>
            <a
              href="src/components/ui/Pagination#"
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Next</span>
              <AiOutlineRight className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  total: PropTypes.number,
  displayTotal: PropTypes.bool,
  pageSize: PropTypes.number,
  className: PropTypes.string,
  currentPage: PropTypes.number,
  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  pageSize: 1,
  currentPage: 1,
  total: 5,
  displayTotal: false,
};
export default PaginationResponsive;
